import React from "react";

const Loader = () => {
	return (
		<div className="loader-container">
			<img src="https://i.giphy.com/media/icDDRafG9Vtb1PNjgS/giphy.webp" className="loader" alt="loading" />
		</div>
	)
}

export default Loader