import { authProvider } from "../utils/authProvider";

const getAuthToken = async () => {
	const token = await authProvider.getIdToken();
	return token.idToken.rawIdToken;
};

const getUser = () => {
	return authProvider.getAccount().userName;
};

export default { getAuthToken, getUser };