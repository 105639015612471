import React from "react";
import "./UpdateHistory.scss";
import UpdateHistoryHeader from "./UpdateHistoryHeader/UpdateHistoryHeader";
import UpdateHistoryListItem from "./UpdateHistoryListItem/UpdateHistoryListItem";

const UpdateHistory = function (props) {
  const [displayCount, setDisplayCount] = React.useState(props.displayCount);

  return (
    <div className="update-history">
      <h1 className="auro_heading auro_heading--500 update-history-text">
        Updates for flight {props.flightNumber}
      </h1>
      {props.updateHistory.error ? <auro-alerts error>{props.updateHistory.error}</auro-alerts> : null}

      {!props.updateHistory.error ? (
        <>
          <UpdateHistoryHeader sortBy={"updatedOn"} sortOrder={"descending"} />
          {props.updateHistory.slice(0, displayCount).map((flight, index) => (
            <UpdateHistoryListItem
              key={`update-history-list-item-${index}`}
              departureDate={flight.departureDate}
              leg={flight.leg}
              updatedOn={flight.updatedOn}
              user={flight.user}
              status={flight.status}
            />
          ))}
		  {props.updateHistory.length > 5 ? (
          <expand-results
            id="toggleResults"
            shown={
              displayCount < props.updateHistory.length
                ? displayCount
                : props.updateHistory.length
            }
            total={props.updateHistory.length}
            onClick={() =>
              displayCount < props.updateHistory.length
                ? setDisplayCount(props.updateHistory.length)
                : setDisplayCount(props.displayCount)
            }
          ></expand-results>
		  ) : null}
        </>
      ) : null}
    </div>
  );
};

export default UpdateHistory;
