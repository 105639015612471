import React from 'react';
import "../Help/Help.scss";
import config from "../../utils/config";

const Outage = (props) => {	


	return (	
			<div className="help-container">
				<h2>System unavailable</h2>
				<p>FALCO will be unavailable until <var>{config.outageEnd}</var>.</p>
				<p><b>Please use SABRE commands to cap flights until that point, and as a reminder, the AUs will need to be adjusted with the inhibitor turned on.</b></p>
				<p>Additionally, please remember to note any flights adjusted during this period so we can verify levels are consistent post-outage.</p>
				<p>We apologize for the inconvenience.</p>
			</div>
	);
};

export default Outage;