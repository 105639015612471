import React from 'react';
import ReactDOM from "react-dom";
import "@alaskaairux/auro-button";
import "@alaskaairux/auro-alerts";
import "./components/Search/Search.css";
import { AzureAD } from 'react-aad-msal';
import { authProvider } from "./utils/authProvider";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
      <AzureAD provider = {authProvider} forceLogin={true}>
				<App logout={authProvider.logout} />
      </AzureAD>
  </React.StrictMode>,
  document.getElementById("root")
);
