import React, {useState} from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import { BrowserRouter, Route } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import Search from "./components/Search/Search";
import Header from "./components/Header/Header";
import Outage from "./components/Outage/Outage";
import Help from "./components/Help/Help";
import SearchResults from "./components/SearchResults/SearchResults";
import UpdateHistory from "./components/UpdateHistory/UpdateHistory";
import updateHistoryService from "./services/updateHistoryService";
import searchService from "./services/searchService";
import updateAuthService from "./services/updateAuthorizationLevelService";
import config from "./utils/config";

const App = (props) => {	
	
	const [outage, setOutage] = React.useState(false);

	const [lookupFlight, setLookupFlight] = React.useState({
		number: "",
		date: "",
	});	
	
	const [loading, setLoading] = useState(false)		

	const [searchResults, setSearchResults] = React.useState([]);
	const [displaySearchResults, setDisplaySearchResults] = React.useState(false);

	const [history, setHistory] = React.useState([]);
	const [displayHistory, setDisplayHistory] = React.useState(false);
	
	const searchFlight = async (flightNumber, flightDate) => {
		toggleOutage();
		setDisplayHistory(false);
		setDisplaySearchResults(false);

		setLookupFlight({
			number: flightNumber,
			date: flightDate,
		});

		await getFlightInfo(flightNumber, flightDate);
		setDisplaySearchResults(true);

		await getHistory(flightNumber, flightDate);
		setDisplayHistory(true);
	};

	const getFlightInfo = async (flightNumber, flightDate) => {
		setLoading(true);
		const rawResponse = await searchService.getFlight(
			config.datedFlightBaseUrl,
			flightNumber,
			flightDate,
			config.apimKey
		);
		setSearchResults(searchService.parseFlight(rawResponse));
	};

	const getHistory = async (flightNumber, flightDate) => {
		const rawResponse = await updateHistoryService.getUpdateHistory(
			config.datedFlightBaseUrl,
			flightNumber,
			flightDate,
			config.apimKey
		);
		setLoading(false);	

		setHistory(updateHistoryService.parseFlightHistory(rawResponse));
	};

	const updateAuthorizationLevel = async (index, authLevels) => {
		let temp = [...searchResults];
		temp[index].cabins = authLevels;

		const result = await updateAuthService.updateAuthorizationLevels(
			config.datedFlightBaseUrl,
			true,
			lookupFlight.number,
			lookupFlight.date,
			temp[index],
			config.apimKey
		);

		if (result.error) {
			temp[index].success = false;
			temp[index].error = true;
		} else {
			temp[index].success = true;
			temp[index].error = false;
		}

		temp[index].cabins = temp[index].cabins.map((cabin) => {
			return {
				...cabin,
				changed: false
			};
		});

		setSearchResults(temp);
		getHistory(lookupFlight.number, lookupFlight.date);
	};
	

	const toggleSearchResultForUpdate = (index) => {
		let temp = [...searchResults];
		temp[index].expanded = temp[index].expanded ? false : true;

		setSearchResults(temp);
	};	

	const toggleOutage = () => {		

		if ((new Date() >= new Date(config.outageStart)) && (new Date() < new Date(config.outageEnd))) {
			setOutage(true)
		  } 
	}

	return (		
		<React.StrictMode>
			<BrowserRouter>
			<Header logout={props.logout} />	
			{outage ? ( <Outage/> ) : 	
				<Route path="/" exact component={ Search }>					
				 <Search onSearch={searchFlight} />
					{displaySearchResults ? (
						<SearchResults id="search-results" searchResults={searchResults} toggle={toggleSearchResultForUpdate} updateAuthLevel={updateAuthorizationLevel} />
					) : null}
					{displayHistory ? (
						<UpdateHistory
							id="update-history"
							flightNumber={lookupFlight.number}
							updateHistory={history}
							displayCount={5}
						/>
					) : null}					
				</Route>
				}
				<Route path="/help" component={ Help }></Route>			
			</BrowserRouter>			
			{loading ? ( <Loader />) : null}				
		</React.StrictMode>
	);
};

export default App;

