import React from "react";
import "./SearchResultsHeader.scss";

const SearchResultsHeader = function (props) {
  return (
    <div className="search-results-header-container auro-text-body-size-sm">
      <div className="search-results-header-item leg">Leg</div>
      <div className="search-results-header-item load">Load</div>
      <div className="search-results-header-item capacity">Capacity</div>
      <div className="search-results-header-item bookings">Bookings</div>
      <div className="search-results-header-item auth">Auth lvl</div>
    </div>
  );
};

export default SearchResultsHeader;
