import React from 'react';
import calendar from '@alaskaairux/icons/dist/icons/interface/calendar.svg';
import "./DatePicker.css"

class DatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dateOptions: [],
			isShowing: false
		}
		this.onClick = this.onClick.bind(this);
		this.onSelectDate = this.onSelectDate.bind(this);

		this.onFlightDateChange = props.onFlightDateChange;
	}

	onClick() {
		const isShowing = this.state.isShowing;
		if (isShowing) {
			this.setState(() => ({
				isShowing: false,
			}))
			return;
		}

		var today = new Date();
		let dateOptions = [];
		dateOptions[0] = "Select day";
		for (var i = 1; i < 4; i++) {
			dateOptions[i] = this.convertDateToString(today);
			today.setDate(today.getDate() + 1);
		}

		this.setState(() => ({
			dateOptions: dateOptions,
			isShowing: true,
		}));
	}

	onSelectDate(event) {
		const pickedDate = event.target.getAttribute("value");
		this.setState(() => ({
			isShowing: false
		}));
		this.onFlightDateChange(pickedDate);
	}

	convertDateToString(date) {
		return ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getDate() + '-' + date.getFullYear();
	}

	renderDate() {
		return (
			<button id='select-date' onClick={this.onClick} className="select-date-button">
				{this.props.flightDate}
			</button>
		)
	}

	renderDateDropdown() {
		const { dateOptions } = this.state;
		const isShowing = this.state.isShowing;

		if (!isShowing) {
			return null;
		}

		return (
			<ul>
				{dateOptions.map((date, index) => <li key={`date-${index}`} value={date} onClick={this.onSelectDate}>{date}</li>)}
			</ul>
		)
	}

	render() {
		return (
			<div className="date-picker">
				<img className="calendar-icon" src={calendar} alt="calendar" />
				{this.renderDate()}
				{this.renderDateDropdown()}
			</div>
		)
	}
}

export default DatePicker;