import React from "react";
import chevronDown from "@alaskaairux/icons/dist/icons/interface/chevron-down.svg";
import "./UpdateHistoryHeader.scss";

const sortBy = {
  DEPARTURE_DATE: 'departureDate',
  LEG: 'leg',
  UPDATED_ON: 'updatedOn',
  USER: 'user',
  STATUS: 'status'
}

const isActive = function(property, value) {
  return property === value;
}

const UpdateHistoryHeader = function(props) {
  return (
    <div className="update-history-header-container auro-text-body-size-sm">
      <div className="update-history-header-item date">
        Departure date
        <img id="update-history-header-departure-date-icon" className={`update-history-header-chevron ${isActive(sortBy.DEPARTURE_DATE, props.sortBy) ? 'active' : 'inactive'}`} src={chevronDown} alt="sort descending"></img>
      </div>
      <div className="update-history-header-item name">
        Leg
        <img id="update-history-header-leg-icon" className={`update-history-header-chevron ${isActive(sortBy.LEG, props.sortBy) ? 'active' : 'inactive'}`} src={chevronDown} alt="sort descending"></img>
      </div>
      <div className="update-history-header-item date">
        Updated
        <img id="update-history-header-updated-on-icon" className={`update-history-header-chevron ${isActive(sortBy.UPDATED_ON, props.sortBy) ? 'active' : 'inactive'}`} src={chevronDown} alt="sort descending"></img>
      </div>
      <div className="update-history-header-item name">
        User
        <img id="update-history-header-user-icon" className={`update-history-header-chevron ${isActive(sortBy.USER, props.sortBy) ? 'active' : 'inactive'}`} src={chevronDown} alt="sort descending"></img>
      </div>
      <div className="update-history-header-item">
        Status
        <img id="update-history-header-status-icon" className={`update-history-header-chevron ${isActive(sortBy.STATUS, props.sortBy) ? 'active' : 'inactive'}`} src={chevronDown} alt="sort descending"></img>
      </div>
    </div>
  );
}

export default UpdateHistoryHeader;