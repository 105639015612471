import authHelper from "./authHelper";

const updateAuthorizationLevels = async (url, highPriority, flightNumber, flightDate, segment, apiKey) => {
  try {
		const user = authHelper.getUser();
		const body = createRequestBody(user, highPriority, flightNumber, flightDate, segment);
		const token = await authHelper.getAuthToken();

		const response = await fetch(`${url}`, {
			"method": "POST",
			"headers": {
				"Authorization": `Bearer ${token}`,
				"Ocp-Apim-Subscription-Key": apiKey,
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(body)
		});

		return await response.json();
	} catch (err) {
		return { error: "Failed to update flight." };
	}
};

const createRequestBody = (user, highPriority, flightNumber, flightDate, segment) => {
	return {
		requestingProcessName: user,
		isHighPriority: highPriority,
		flights: [
			{
				flightNumber,
				flightDate: new Date(flightDate).toISOString(),
				legs: [{
					boardPointIataCode: segment.origin,
					offPointIataCode: segment.destination,
					cabinCounters: segment.cabins.map((cabin) => {
						return {
							cabinCode: cabin.code,
							overBookingPercentageDetails: {
								override: 0
							}
						}
					})

				}],
				segments: [{
					boardPointIataCode: segment.origin,
					offPointIataCode: segment.destination,
					bookingClassList: segment.cabins.map((cabin) => {
						return {
							code: cabin.code,
							cabinCode: cabin.code,
							authorizationLevelDetails: {
								override: cabin.authLevel
							}
						}
					})
				}],
			}
		]
	};
}

export default { updateAuthorizationLevels, createRequestBody };