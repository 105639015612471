import React, { Component } from 'react';
import DatePicker from './DatePicker/DatePicker';
import FlightNumberInput from './FlightNumberInput/FlightNumberInput';

class Search extends Component {
	constructor(props) {
		super(props);

		this.state = {
			flightNumber: "",
			flightDate: this.convertDateToString(new Date())
		}

		this.handleClearSearchClick = this.handleClearSearchClick.bind(this);
		this.onFlightNumberChange = this.onFlightNumberChange.bind(this);
		this.onFlightDateChange = this.onFlightDateChange.bind(this);	
		this.handleKeyPress = this.handleKeyPress.bind(this);	

		this.onSearch = props.onSearch;
	}
	
	handleClearSearchClick() {
		this.setState({
			flightNumber: "",
			flightDate: this.convertDateToString(new Date())
		});
		window.location.href = '/';

	}

	renderClearSearchButton() {
		return (
			<div className="clear-search-button">
				<auro-button id="clear-search" tertiary onClick={this.handleClearSearchClick}>
					Clear search
        </auro-button>
			</div>
		)
	}

	renderSearchButton() {
		return (
			<div className="search-button">
				<auro-button id="search" secondary onClick={this.searchFlight} >
					Search
        		</auro-button>
			</div>
		)
	}

	convertDateToString(date) {
		return ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getDate() + '-' + date.getFullYear();
	}

	searchFlight = (e) => {
		if (this.state.flightNumber !== "" && this.state.flightDate !== "Select day") {
			this.onSearch(this.state.flightNumber, this.state.flightDate);
		}		
	}

	onFlightNumberChange(newFlightNumber) {
		this.setState({ flightNumber: newFlightNumber });
	}

	onFlightDateChange(newFlightDate) {
		this.setState({ flightDate: newFlightDate });
	}

	handleKeyPress = (e) => {
		if(e.which === 13) {
			this.searchFlight();
	}}	

	render() {
		return (
			<>
				<div className="search" onKeyPress={this.handleKeyPress}>
					<DatePicker
						id='datePicker'
						onFlightDateChange={this.onFlightDateChange}
						flightDate={this.state.flightDate}
					/>
					<FlightNumberInput
						id='flightNumberInput'
						onFlightNumberChange={this.onFlightNumberChange}
						flightNumber={this.state.flightNumber}
					/>
					{this.renderSearchButton()}
					{this.renderClearSearchButton()}					
				</div>
			</>
		);
	}
}

export default Search;
