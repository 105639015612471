import React from "react";
import "./UpdateHistoryListItem.scss";

const UpdateHistoryListItem = function(props) {
  return (
    <div className="update-history-list-item-container">
      <span className="update-history-list-item date">
        {props.departureDate}
      </span>
      <span className="update-history-list-item name">
        {props.leg}
      </span>
      <span className="update-history-list-item date">
        {props.updatedOn}
      </span>
      <span className="update-history-list-item name">
        {props.user}
      </span>
      <span className="update-history-list-item">
        {props.status}
      </span>
    </div>
  );
}

export default UpdateHistoryListItem;