import React from "react";
import "./UpdateAuthLevel.scss";
import SearchResultsListItem from "../SearchResultsListItem/SearchResultsListItem";
import UpdateAuthLevelInput from "./UpdateAuthLevelInput/UpdateAuthLevelInput";

const UpdateAuthLevel = (props) => {
	const [authLevels, setAuthLevels] = React.useState(props.segment.cabins.map((cabin) => {
		return {
			...cabin
		};
	}));

	const updateAuthLevel = (code, level) => {
		const originalAuthLevel = props.segment.cabins.find(cabin => cabin.code === code).authLevel;
		const index = authLevels.findIndex(cabin => cabin.code === code);

		let temp = [...authLevels];
		temp[index].authLevel = Number(level);
		temp[index].changed = originalAuthLevel !== Number(level);

		setAuthLevels(temp);
	}

	const anyLevelChanged = () => {
		return authLevels.some(level => level.changed === true);
	}

	const getAuthorizationText = () => {
		if (props.segment.error && !anyLevelChanged()) {
			return "Error updating levels";
		} else if (anyLevelChanged()) {
			return "Unsaved changes";
		} else if (props.segment.success) {
			return "Updated";
		}
	}

	const updateLocalAuthLevels = async () => {
		await props.sendUpdatedAuthLevel(props.index, authLevels);

		setAuthLevels([...props.segment.cabins]);
	};

	return (
		<div className="update-auth-level-container" id={`update-auth-level-container-${props.segment.origin}-${props.segment.destination}`}>
			<SearchResultsListItem origin={props.segment.origin} destination={props.segment.destination} load={props.segment.load} cabins={props.segment.cabins} expanded={true} index={props.index} toggle={props.onCancel} />
			<div className="update-auth-level-container-body">
				<div className="update-auth-level-container-body-input">
					{
						authLevels.map((cabin, index) => {
							return <UpdateAuthLevelInput
											key={`update-auth-level-cabin-container-input-${index}`}
											origin={props.segment.origin}
											destination={props.segment.destination}
											cabin={cabin}
											onValueChange={updateAuthLevel}
										/>
						})
					}
				</div>
				<div className="update-auth-level-container-body-buttons">
					<auro-button id={`btn-cancel-${props.segment.origin}-${props.segment.destination}`} tertiary onClick={(e) => { props.onCancel(props.index); }}>
						Cancel
					</auro-button>
					<div className="update-auth-level-container-body-buttons-authorization">
						<auro-button
							id={`btn-authorize-${props.segment.origin}-${props.segment.destination}`}
							primary
							{...anyLevelChanged() ? {} : { disabled: true }}
							onClick={(e) => { updateLocalAuthLevels() }}
						>
							Authorize
						</auro-button>
						<span className={`update-auth-level-authorization-text ${props.segment.error || anyLevelChanged() ? "error" : "success"}`}>
							{getAuthorizationText()}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpdateAuthLevel;