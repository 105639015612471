import React from "react";
import "./SearchResultsListItem.scss";
import chevronDown from "@alaskaairux/icons/dist/icons/interface/chevron-down.svg";
import chevronRight from "@alaskaairux/icons/dist/icons/interface/chevron-right.svg";

const SearchResultsListItem = (props) => {
	return (
		<div id={`search-results-container-header-${props.origin}-${props.destination}`}className="search-results-container-header" onClick={(e) => { props.toggle(props.index)}}>
			<div className="search-results-item leg">
				<img id={`search-results-icon-${props.origin}-${props.destination}`} className="search-results-chevron" src={props.expanded ? chevronDown : chevronRight} alt={props.expanded ? "collapse" : "expand" }></img>
				{props.origin} &#8212; {props.destination}
			</div>
			<div className="search-results-item load">
				{props.load}%
			</div>
			<div className="search-results-item capacity">
				{
					<span>
						{props.cabins.map((cabin, index) => {
							return (<React.Fragment key={`search-results-cabin-capacity-${props.origin}-${props.destination}-${index}`} >{index === 0 ? null : <span> &#8212;</span>} {cabin.capacity}{cabin.code}</React.Fragment>)
						})}
					</span>
				}
			</div>
			<div className="search-results-item bookings">
				{
					props.cabins.map((cabin, index) => {
						return (<React.Fragment key={`search-results-cabin-bookings-${props.origin}-${props.destination}-${index}`}> {index === 0 ? null : <span>&#8212;</span>} {cabin.bookings}{cabin.code}</React.Fragment>)
					})
				}
			</div>
			<div className="search-results-item auth">
				{
					props.cabins.map((cabin, index) => {
						return (<React.Fragment key={`search-results-cabin-authlevel-${props.origin}-${props.destination}-${index}`}> {index === 0 ? null : <span>&#8212;</span>} {cabin.authLevel}{cabin.code}</React.Fragment>)
					})
				}
			</div>
		</div>
	);
};

export default SearchResultsListItem;