import React from "react";
import "./SearchResults.scss";
import SearchResultsListItem from "./SearchResultsListItem/SearchResultsListItem";
import SearchResultsHeader from "./SearchResultsHeader/SearchResultsHeader";
import UpdateAuthLevel from "./UpdateAuthLevel/UpdateAuthLevel";

const SearchResults = function (props) {
  return (
    <div className="search-results">
      {props.searchResults.error ? (
        <auro-alerts error>{props.searchResults.error}</auro-alerts>
      ) : (
        <div>
          <SearchResultsHeader />
          {props.searchResults.map((segment, index) => {
            if (segment.expanded) {
              return (
                <div
                  className="search-results-list-item-container"
                  key={`search-results-list-item-expanded-${index}`}
                >
                  <UpdateAuthLevel
                    segment={segment}
                    index={index}
                    onCancel={props.toggle}
                    sendUpdatedAuthLevel={props.updateAuthLevel}
                  />
                </div>
              );
            } else {
              return (
                <div
                  className="search-results-list-item-container"
                  key={`search-results-list-item-collapsed-${index}`}
                >
                  <SearchResultsListItem
                    origin={segment.origin}
                    destination={segment.destination}
                    load={segment.load}
                    cabins={segment.cabins}
                    index={index}
                    toggle={props.toggle}
                  />
                </div>
              );
            }
          })}
		 
        </div>
      )}
    </div>
  );
};

export default SearchResults;
