import authHelper from "./authHelper";

const getUpdateHistory = async (url, flightNumber, flightDate, apiKey) => {
  try {
		const token = await authHelper.getAuthToken();
    const response = await fetch(
      `${url}/${flightNumber}/date/${flightDate}/updates?page=0&pageSize=100`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": apiKey,
          Accept: "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    return { error: "Failed to retrieve dated flight updates." };
  }
};

const parseFlightHistory = (rawFlightHistory) => {
  let updates = [];

  if (rawFlightHistory.error) {
    return rawFlightHistory;
  } else if (!rawFlightHistory || rawFlightHistory.length === 0) {
    return { error: "No flight update history found." };
  }

  rawFlightHistory.forEach((flightUpdate) => {
    if (flightUpdate.segments && flightUpdate.segments.length > 0) {
      flightUpdate.segments.forEach((segment) => {
        let status = "";

        if (
          flightUpdate.responseStatusCode === null ||
          flightUpdate.responseStatusCode === undefined
        ) {
          status = "Requested";
        } else if (flightUpdate.responseStatusCode === "200") {
          status = "Completed";
        } else {
          status = "Failed";
        }

        let update = {
          departureDate: flightUpdate.flightDate.slice(
            0,
            flightUpdate.flightDate.indexOf("T")
          ),
          leg: `${segment.boardPointIataCode} - ${segment.offPointIataCode}`,
          updatedOn: flightUpdate.createdAt.slice(
            0,
            flightUpdate.flightDate.indexOf("T")
          ),
          user: flightUpdate.requestingProcessName,
          status,
        };

        updates.push(update);
      });
    }
  });

  return updates;
};

export default { getUpdateHistory, parseFlightHistory };
