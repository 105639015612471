import React, { Component } from 'react';
import "./Help.scss";

class Help extends Component {

render() {
	return (
		<div className="auro_heading help-container">
	  		<h1 className="auro_heading--600">
				Falco Help 
	  		</h1>
			  <div className="help-section">
	 		<h1 className="auro_heading--500 help-section">
				How To:
	  		</h1>
			  <div className="help-text">
				<p>The Flight Authorization Level Control for Operations (FALCO) web app is designed to provide a convenient means for SOC and NOC users to modify cabin auth levels on flights departing within the next three days.</p>
				<p>Logging in to the web page is performed using Single-Sign-On.  Make sure to keep any personal devices needed for multi-factor authentication nearby, and if you are using a shared computer, click 'No' when asked to 'Stay signed in'.</p>
				<p>Start by entering the flight number of the leg to be modified and the desired departure date, then click 'Search' in the bar at the top of the page.</p>
				<p>The page will become populated with a list of all the legs that meet your search criteria.  Each leg will be displayed with the current auth levels, current bookings, and current capacity per cabin, as well as the current ship load factor.</p>
				<p>Click a row to access the auth level control forms for that leg.  Enter new auth levels in the provided forms and click 'Authorize'. <i>Note that the UI will not allow new auth levels greater than cabin capacity.</i> Click 'Cancel' at any time to clear unsaved adjustments.</p>
				<p>Once updated auth levels have been saved, the record of your adjustment along with your name will be displayed at the bottom of the page under 'Updates'.  All previous updates for the same flight number and departure date will be recorded here.</p>
				<p>Click 'Clear search' to clear both the search criteria and the search results.</p>
			  </div>
			  </div>
			  <div className="help-section">
			  <h1 className="auro_heading--500 help-section">
			  Problems?
	  		</h1>
			  <div className="help-text">
			  	<p>For any technical issues, please send an email to CommOpsDev@alaskaair.com.</p>
			  </div>
	  		 </div>
		</div>
	);
}
}

export default Help;

