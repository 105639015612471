import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from "msal";
import config from "./config";

// Msal Configurations
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: "https://login.microsoftonline.com/"+ config.tenant,
      clientId: config.clientId,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      navigateToLoginRequestUrl: false
    },
    system: {
      logger: new Logger(
        (logLevel, message, containsPii) => {

        },
        {
          level: LogLevel.Verbose,
          piiLoggingEnabled: false
        }
      )
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  },
  {
    scopes: [
		 config.scope
    ]
  },
  {
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: window.location.origin + "/auth.html"
  }
);