import authHelper from "./authHelper";
const fetch = require('node-fetch');

const getFlight = async (url, flightNumber, flightDate, apiKey) => {
	try {
		const token = await authHelper.getAuthToken();
		const response = await fetch(`${url}/${flightNumber}/date/${flightDate}`, {
			"method": "GET",
			"headers": {
				"Authorization": `Bearer ${token}`,
				"Ocp-Apim-Subscription-Key": apiKey,
				"Accept": "application/json"
			}
		});
		return await response.json();
	} catch (e) {
		return { error: "Failed to get flight info" };
	}
};

const parseFlight = (flight) => {
	if (!flight || !flight.legs) {
		return { error: "Flight not found" };
	}

	if (!flight.legs || flight.legs.length < 1) {
		return { error: "No leg found" };
	}

	let legs = [];
	flight.legs.forEach((leg) => {
		if (leg.cabins && leg.cabins.length > 0) {
			const load = Math.round((leg.cabins.reduce((a, b) => a + b.bookings, 0)) / (leg.cabins.reduce((a, b) => a + b.operationalCapacity, 0)) * 100);

			let resultedleg = {
				origin: leg.boardPoint,
				destination: leg.offPoint,
				load,
				cabins: leg.cabins.map((cabin) => {
					return {
						code: cabin.cabinCode,
						capacity: cabin.operationalCapacity,
						bookings: cabin.bookings,
						authLevel: cabin.authorizationLevel
					}
				})
			};

			legs.push(resultedleg);
		}
	});

	return legs;
};

export default { getFlight, parseFlight };