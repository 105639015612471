import React, {Component} from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

class Header extends Component {

	render() {

		return (
			<div className="app-header">			
				<img className="logo" alt="Alaska Airlines" src="https://resource.alaskaair.net/-/media/A2B28D27C57B4BB9A72557221CF779A3"></img>
				<span className="line"></span>				
				<Link to="/" className="auro_heading auro_heading--800 link-style main">Falco</Link>			
				<label onClick={this.props.logout} className="label-sm link-style logout">Logout</label>
				<Link to="Help" className="label-sm link-style help">Help</Link>			
			</div>
		);
	}
}

export default Header;
