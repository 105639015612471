import React from "react";
import "./UpdateAuthLevelInput.scss";

const UpdateAuthLevelInput = (props) => {
	return (
		<div className="update-auth-level-input-container">
			<span className="update-auth-level-input-label">
				{`${props.cabin.code} authorization level:`}
			</span>
			<input
				id={`update-auth-level-input-${props.origin}-${props.destination}-${props.cabin.code}`}
				className={`update-auth-level-input ${props.cabin.changed === true ? "changed" : ""}`}
				value={props.cabin.authLevel}
				onChange={(e) => props.onValueChange(props.cabin.code, e.target.value)}
			/>
		</div>
	);
};

export default UpdateAuthLevelInput;