import React from 'react';
import search from '@alaskaairux/icons/dist/icons/interface/search.svg';
import "./FlightNumberInput.css"

class FlightNumberInput extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
		this.onFlightNumberChange = props.onFlightNumberChange;
    }

    onChange(event) {
        const text = event.target.value;
        this.onFlightNumberChange(text);
	}

    render() {
        return (
            <div className="flight-search-input">
                <img className="search-icon" src={search} alt="search" />
                <input
                    id='input'
                    type="text"
					placeholder="Search flight number"
					onChange={this.onChange}
                    maxLength="4"
                />
            </div>
        )
    }
}

export default FlightNumberInput;